
import './App.css';
import { Route, Routes } from "react-router-dom";
import Layout from './components/Layout';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Profile from './components/Profile';
import Content from './components/Content.js';
import Domain from './components/Domain';
function App() {
  
  
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");
  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  return (
    
      <Routes>
        <Route path="/" element={<Layout />} >

        <Route index element={<Home/>} />

        <Route path={"/create-content"} element={<Content/>} />
        <Route path={"/login"} element={<Login/>} />
        <Route path={"/register"} element={<Register/>} />
        <Route path={"/profile"} element={<Profile/>} />
        <Route path={"/add-domain"} element={<Domain/>} />

        </Route>
      </Routes>
    
  );
}

export default App;
