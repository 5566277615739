import { useState } from 'react'
import React from 'react'
function Domain() {
    
    const [domain, setDomain] = useState('')
    return (
        <>
            <div style={{ backgroundColor: "white" }} className="container">
                <form>
                    <h1>Add subdomain</h1>
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="subdomaain">Name</label>
                        <input type="text" id="form4Example1" onChange={e=>setDomain(e.target.value)} className="form-control" />
                    </div>
                    <h5>Your personal domain will be  </h5>
                    <h6><a className='disabled' href={domain}>https://{domain}.docopypaste.live</a></h6>
                    

                   
                    <button type="submit" className="btn btn-primary btn-block mb-4">Create Contact</button>
                </form>
            </div>
        </>
    )
}



export default Domain