import CodeEditor from '@uiw/react-textarea-code-editor';
import { useState } from 'react'
import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
function Content() {
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-2' }, { 'indent': '+2' }],
            ['link', 'image'],
            ['clean']
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]
    const [code, setCode] = React.useState(
        `function add(a, b) {\n  return a + b;\n}`
    );
    const handlechange = (value) => {
        setContent(value)
        console.log(value)
    }
    const [content, setContent] = useState('')
    return (
        <>
            <div style={{ backgroundColor: "white" }} className="container">
                <form>
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="form4Example1">Name</label>
                        <input type="text" id="form4Example1" className="form-control" />
                    </div>

                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="form4Example2">Email address</label>
                        <input type="email" id="form4Example2" className="form-control" />
                    </div>

                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="form4Example3">Message</label>
                        <ReactQuill theme="snow" value={content} onChange={value=>handlechange(value)} modules={modules} formats={formats} />
                    </div>
                    {/* <div className="form-outline mb-4">
                        <CodeEditor
                            value={content}
                            language="js"
                            placeholder="Please enter JS code."
                            onChange={(evn) => setCode(evn.target.value)}
                            padding={15}
                            style={{
                                fontSize: 12,
                                backgroundColor: "#f5f5f5",
                                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                            }}
                        />
                    </div> */}
                    <div style={{border: "1px solid black"}} className="form-outline mb-4">
                        <ReactQuill
                            value={content}
                            readOnly={true}
                            theme={"bubble"}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary btn-block mb-4">Create Contact</button>
                </form>
            </div>
        </>
    )
}



export default Content