import React, { useState } from 'react'
import "../assets/login.css"
import axiosInstance from '../api/api'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux'
import { addNewUser } from '../store/slices/authSlice'


export default function Login ()  {
    const auth = useSelector((state) => state.auth.value)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        const userData = {
            email: email,
            password: password
          };
            setEmail("")
            setPassword("")
       
          try {
            const res = await axiosInstance.post("/auth/login", userData,{withCredentials: true});
            // remove this console.log after testing
            
            
            if (res.status===200) {
                // alert.success("Login Successful");
                toast.success('Successfully Login!')
                axiosInstance.defaults.headers.post['Authorization'] = "Bearer "+res.data.accessToken;
                const userInfo=res.data.userInfo
                console.log("from data" ,userInfo);
                dispatch(addNewUser(userInfo))
               

                navigate("/");
            }
        } catch (error) {
            if (error.response) {
                console.log(error.response);
                toast.error(error.response.data.message)
            } else if (error.request) {
                toast.error('network error')
                console.log("network error");
            } else {
                toast.error(error)
            }
        }
  };
    return (
        <>
        <div><Toaster position="bottom-left"
            reverseOrder={false}/></div>

            <div className='container'>
                <div className='window'>
                    <div className='overlay'></div>
                    <form className='content' onSubmit={handleSubmit}>
                        <div className='welcome'>Hello There!</div>
                        <div className='subtitle'>Before using our services you need to Log In.</div>
                        <div className='input-fields'>
                            <input id="email" type='email' placeholder='Email' value={email} onChange={e=>setEmail(e.target.value)} className='input-line full-width'></input>
                            <input id="password" type='password' placeholder='Password' value={password} onChange={e=>setPassword(e.target.value)} className='input-line full-width'></input>
                        </div>
                        <div className='spacing'>or continue with <span className='highlight'>Facebook</span></div>
                        <div><button className='ghost-round full-width'>Login</button></div>
                    </form>
                </div>
            </div>

        </>

    )
}

