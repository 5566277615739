import React, { useState } from 'react';
import "../assets/login.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';


const Register = () => {
    const message="";
    const navigate = useNavigate();

    const [Name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault()
        const userData = {
            name: Name,
            email: email,
            password: password
          };
            setEmail("");
            setPassword("");
            setName("");
          try {
            const res = await axios.post("http://localhost:5000/auth/signup", userData,{withCredentials: true});
            // remove this console.log after testing
            console.log(res);
            if (res.status===201) {
                
                toast.success('Registered Successfully')
                navigate("/login");
            }
        } catch (error) {
            if (error.response) {
                console.log(error.response);
                toast.error(error.response.data.message)
            } else if (error.request) {
                toast.error('network error')
            } else {
                toast.error(error)
            }
        }

  };

    return (
        <>
            <div><Toaster position="bottom-left"
            reverseOrder={false}/></div>
            <div className='container'>
            <div className='bold-line'></div>
            <div className='container'>
                <div className='window'>
                    <div className='overlay'></div>
                    <form className='content' onSubmit={handleSubmit}>
                        <div className='welcome'>Hello There!</div>
                        <div className='subtitle'>We're almost done. Before using our services you need to create an account.</div>
                        <div className='input-fields' >
                            <input type='text' placeholder='Name' className='input-line full-width' value={Name} onChange={e=>setName(e.target.value)} required></input>
                            <input type='email' placeholder='Email' className='input-line full-width' value={email} onChange={e=>setEmail(e.target.value)} required></input>
                            <input type='password' placeholder='Password' className='input-line full-width' value={password} onChange={e=>setPassword(e.target.value)} required></input>
                        </div>
                        <div className='spacing'>or continue with <span className='highlight'>Facebook</span></div>
                        <div><button type='submit' className='ghost-round full-width'>Create Account</button></div>
                    </form>
                {message}
                </div>
            </div>
        </div>
        </>

    )
}

export default Register